import React from "react"
import { Link, graphql, PageProps } from "gatsby"
import Layout from "../components/layouts/Layout2"
import { Hero, Breadcrumb, Map } from "../components/slices"
import { Helmet } from "react-helmet"
import { renderMetaTags } from "../utils"
import { SliceRenderer } from "../components/slices"

interface StandardPageProps {
  data: {
    datoCmsService: {
      title: string
      backgroundImage: {
        url: string
      }
    }
  }
  pageContext: pageContent
  location: {
    href: string
  }
}

interface breadcrumbItem {
  title: string
  url: string
}

interface pageContent {
  parent: {
    title: string
    url: string
  }
}

const Service = ({
  data,
  pageContext,
  location,
}: PageProps<StandardPageProps>) => {
  const { title, backgroundImage, content, seoMetaTags } = data.datoCmsService
  const { title: parentTitle, url: parentUrl } = pageContext.parent
  let breadcrumbData: breadcrumbItem[] = [{ title: "Home", url: "/" }]

  breadcrumbData = [
    ...breadcrumbData,
    {
      title: parentTitle,
      url: parentUrl,
    },
    {
      title,
      url: location.pathname,
    },
  ]

  const heroProps = {
    title,
    variation: "small",
    mainImage: backgroundImage,
  }
  return (
    <Layout>
      <Hero {...heroProps} />
      {seoMetaTags.tags && <Helmet>{renderMetaTags(seoMetaTags.tags)}</Helmet>}
      <div className="container -pinched">
        <Breadcrumb data={breadcrumbData} />
        <SliceRenderer slices={content} variation="small" />
      </div>

      {/* <div className="call-to-action">
        <div className="container">
          <div className="call-to-action__inner">
            <p className="[ title h3 ]">
              Book your appointment with {firstName} now
            </p>
            <Link className="button" to="/contact">
              Contact us
            </Link>
          </div>
        </div>
      </div> */}

      <Map />
    </Layout>
  )
}

export const query = graphql`
  query Service($url: String) {
    datoCmsService(url: { eq: $url }) {
      title
      backgroundImage {
        url
      }
      seoMetaTags {
        tags
      }
      content {
        __typename
        ... on DatoCmsText {
          id
          title
          text {
            value
          }
          backgroundText
        }
        ... on DatoCmsTextBoxWithImage {
          id
          title
          content
          image {
            url
          }
          link {
            ... on DatoCmsStandardContentPage {
              url
            }
          }
          linkText
          alignment
          originalId
        }
        ... on DatoCmsGalleryBlock {
          title
          content
          gallery {
            url
            ... on DatoCmsFileField {
              url
            }
          }
          link {
            model {
              name
            }
            ... on DatoCmsGallery {
              id
              url
            }
          }
          use4MostRecentGalleryEntries
        }
      }
    }
  }
`

export default Service
